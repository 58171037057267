import React from "react";
import PropTypes from "prop-types";
import "./Legend.scss";

const Legend = ({ colors }) => {
  return (
    <div className="legend-container">
      <div className="legend-colors">
        {colors.map((color, index) => (
          <div className="color" key={index}>
            <div className="step-color">{color.hexagon}</div>
            <div className="color-label">
              consumo <span>{color.label}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="legend-title">Média móvel</div>
    </div>
  );
};

Legend.propTypes = {
  colors: PropTypes.array.isRequired
};

export default Legend;

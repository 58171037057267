import { ActuatorsService, Service } from "./Service";
import { geoData } from "../data/campusData";
import { isNotACampus } from "../utils/EnvUtils";

const buildingsIds = [
  ...new Set(geoData.features.map(building => building.properties.buildingId))
];

const coreAuth = async () => {
  const LCToken = localStorage.getItem("AuthToken");
  let coreAuth = await Service.get("auth/forgotten", {
    params: {
      token: LCToken
    }
  }).then(res => res.data);
  return coreAuth;
};

const getCurrentTabIsActuators = () => {
  const currentTab = localStorage.getItem("CurrentTab");
  return currentTab === "actuators";
};

export const getActuators = async buildingId => {
  if (!getCurrentTabIsActuators()) return [];

  let actuators = undefined;
  const user = localStorage.getItem("user");
  if (coreAuth()) {
    actuators = await ActuatorsService.get(
      `liteme/params?email=${JSON.parse(user)["email"]}&provider=${isNotACampus ? "PAINEL" : "SET"
      }`
    )
      .then(res => (res ? res.data : []))
      .catch(err => {
        console.error(err);
        return [];
      });
  }

  actuators.forEach(actuator => {
    actuator["switch_state"] = actuator["switch_state"] === "on" ? true : false;

    if (!buildingsIds.includes(actuator.building_id.$oid)) {
      actuator.building_id.$oid = "5cb4c2f34f0cc9a612229474";
    }
  });

  if (buildingId) {
    actuators = actuators.filter(
      actuator => actuator.building_id.$oid === buildingId
    );
  }
  return actuators;
};

export const updateSwitchState = async (hardware_id, newState) => {
  const formData = {
    hardwareId: hardware_id,
    state: newState ? "on" : "off",
    authentication: localStorage.getItem("AuthData"),
    email: JSON.parse(localStorage.getItem("user"))["email"]
  };

  try {
    if (isNotACampus) {
      if (coreAuth()) {
        await ActuatorsService.post("liteme/switch_state", formData)
          .then(res => (res ? res.data : undefined))
          .catch(err => console.error(err));
        return true;
      }
    } else {
      await ActuatorsService.post("liteme/switch_state", formData)
        .then(res => (res ? res.data : undefined))
        .catch(err => console.error(err));
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

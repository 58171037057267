import React from "react";

function Hexagon({ stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 145.218 166.136"
    >
      <g
        data-name="Grupo 610"
        transform="matrix(.83536 0 0 .83536 -3338.998 -717.358)"
      >
        <g
          data-name="Grupo 609"
          transform="translate(-188.573 -39.802) scale(1.04615)"
        >
          <path
            fill="#fff"
            strokeWidth="38.525"
            d="M1787.172 2753.845v-78.067l-67.609-38.052-67.609 38.052v78.067l67.609 39.034z"
            data-name="Caminho 4355"
            transform="translate(2365.545 -1758.225)"
          ></path>
          <path
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="29.927"
            d="M4151.717 992.62v-78.067l-67.609-39.034-67.609 39.034v78.067l67.609 39.034z"
            data-name="Caminho 4356"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Hexagon;

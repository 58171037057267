import React from "react";

import "../../Dashboard.scss";
import "./Energy.scss";
import EnergyWidgets from "./EnergyWidgets";
import EnergyDetailed from "./EnergyDetailed";

const Energy = ({ showDetailed, showAll, ...props }) => {
  return showAll && showDetailed ? (
    <EnergyDetailed showAll={showAll} {...props} />
  ) : (
    <EnergyWidgets showAll={showAll} {...props} />
  );
};

export default Energy;

import React, { useState, useEffect } from "react";
import centerOfMass from "@turf/center-of-mass";
import * as turf from "@turf/helpers";
import Map from "../Map/Map";
import SideMenu from "../SideMenu/SideMenu";
import DefaultDashboard from "../../Dashboard/DefaultDashboard";
import "./Dashboard.scss";
import { allBuildingsData } from "../../selectors/data";
import { EnergyDetailedFilters } from "../../Dashboard/Tabs/Energy/EnergyDetailedFilters";

import { geoData, defaultCenterPoint, zoomCampus } from "../../data/campusData";

import { connect, useSelector } from "react-redux";
import { fetchAccessPoints } from "../../actions/wifi";

import { getCoordinates } from "../../utils/GeoUtils";
import { isNotACampus } from "../../utils/EnvUtils";

const Dashboard = ({ fetchAccessPoints, closed }) => {
  const [centerPoint] = useState(defaultCenterPoint);
  const [reset, setReset] = useState(false);

  const [area, setSelectedArea] = useState({
    buildingId: "",
    name: ""
  });

  const [style, setStyle] = useState("energy");

  const [selectedAlert, setSelectedAlert] = useState("");

  const [marker, setMarker] = useState([]);

  const [loggedUser, setLoggedUser] = useState(undefined);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isReadOnlyUser, setIsReadOnlyUser] = useState(true);

  const [authorizedGeoData, setAuthorizedGeoData] = useState(geoData);
  const [selectedAlertArea, setSelectedAlertArea] = useState({
    buildingId: "",
    name: ""
  });

  const [activeFilter, setActiveFilter] = useState(EnergyDetailedFilters[1]);

  const [activeCategory, setActiveCategory] = useState("");

  let allBuildings = useSelector(allBuildingsData);

  let [timeType, setTimeType] = useState("today");

  const createMarkers = () => {
    setSelectedAlert("");
    let markers = isNotACampus ? [] : [...marker];

    authorizedGeoData.features.forEach(feature => {
      let center = centerOfMass(turf.polygon(getCoordinates(feature)));
      markers.push({
        lat: center.geometry.coordinates[1],
        lng: center.geometry.coordinates[0],
        properties: feature.properties
      });
    });

    setMarker(markers);
  };

  useEffect(() => {
    setSelectedAlertArea({
      buildingId: "",
      name: ""
    });
    fetchAccessPoints();
  }, [style]);

  useEffect(() => {
    createMarkers();
  }, [authorizedGeoData]);

  useEffect(() => {
    createMarkers();
  }, []);

  const [markersAllowed, setMarkersAllowed] = useState([]);
  const [filteredMarkersAllowed, setFilteredMarkersAllowed] = useState([]);

  useEffect(() => {
    setMarkersAllowed(
      marker.filter(m => !m.properties.isPrivate || loggedUser)
    );
  }, [marker]);

  useEffect(() => {
    setFilteredMarkersAllowed(markersAllowed);
  }, [markersAllowed]);

  useEffect(() => {
    if (selectedAlert) {
      authorizedGeoData.features.forEach(feature => {
        if (feature.properties.buildingId === selectedAlert) {
          setSelectedAlertArea(feature);
        }
      });
    } else {
      setSelectedAlertArea({
        buildingId: "",
        name: ""
      });
    }
  }, [selectedAlert]);

  return (
    <div className="dashboard-container">
      <SideMenu
        area={area}
        closed={closed}
        tab={style}
        setTab={setStyle}
        isSuperUser={isSuperUser}
      >
        <DefaultDashboard
          area={area}
          geoJson={authorizedGeoData}
          areas={authorizedGeoData.features}
          onSelectArea={setSelectedArea}
          onAlertClick={setSelectedAlert}
          setSelectedArea={setSelectedArea}
          setAuthorizedGeoData={setAuthorizedGeoData}
          loggedUser={loggedUser}
          setLoggedUser={setLoggedUser}
          isSuperUser={isSuperUser}
          setIsSuperUser={setIsSuperUser}
          isReadOnlyUser={isReadOnlyUser}
          setIsReadOnlyUser={setIsReadOnlyUser}
          setReset={setReset}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          timeType={timeType}
          setTimeType={setTimeType}
          markers={markersAllowed}
          filteredMarkers={filteredMarkersAllowed}
          setFilteredMarkers={setFilteredMarkersAllowed}
        ></DefaultDashboard>
      </SideMenu>
      <Map
        allAreasData={allBuildings}
        style={style}
        geoJson={authorizedGeoData}
        center={centerPoint}
        onPolygonClick={setSelectedArea}
        selectedAlertArea={selectedAlertArea}
        marker={marker}
        zoom={zoomCampus}
        reset={reset}
        setReset={setReset}
        loggedUser={loggedUser}
        area={area}
        activeFilter={activeFilter}
        timeType={timeType}
        filteredMarkersAllowed={filteredMarkersAllowed}
      ></Map>
    </div>
  );
};

export default connect(null, { fetchAccessPoints })(Dashboard);

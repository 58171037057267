import React from "react";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import "./Navbar.scss";

import { connect } from "react-redux";
import { signOut } from "../../actions/auth";
import { isNotACampus, isPMCGEnv } from "../../utils/EnvUtils";

const mapStateToProps = state => {
  return {
    user: state.auth
  };
};

const Navigator = ({ user, signOut }) => {
  const loginButton = () => {
    if (user.isSignedIn) return <Nav.Link onClick={signOut}>LOGOUT</Nav.Link>;
    else return <Nav.Link href="/login">LOGIN</Nav.Link>;
  };

  const getENVClassName = () => {
    const env = process.env.REACT_APP_CAMPUS.toLowerCase();

    if (isNotACampus) return "liteme-maps";

    return env;
  };

  const isDarkNavbar = () => {
    return getENVClassName() === "litecampus-alt";
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant={isDarkNavbar() ? "dark" : "light"}
      className={"nav " + getENVClassName()}
    >
      <Navbar.Brand
        href="/"
        className={"logo " + getENVClassName()}
      ></Navbar.Brand>
      {isPMCGEnv && (
        <Navbar.Brand href="/" className="logo pmcg-alt"></Navbar.Brand>
      )}
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Row>
          <Col xs={6}>
            <Nav>
              <Nav.Link href="https://www.liteme.com.br/dashboards/campus">
                SOBRE
              </Nav.Link>
              <Nav.Link href="https://www.liteme.com.br/about">
                PARCEIROS
              </Nav.Link>
              <Nav.Link href="https://www.liteme.com.br/join">
                FAÇA PARTE
              </Nav.Link>
              <Nav.Link href="/faq">FAQ</Nav.Link>
            </Nav>
          </Col>
          <Col xs={6} className="login-button">
            <Nav>{loginButton()}</Nav>
          </Col>
        </Row>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default connect(mapStateToProps, { signOut })(Navigator);

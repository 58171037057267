import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faChevronLeft,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import "./AreaHeader.scss";
import DateWidget from "../DateWidget/DateWidget";

const AreaHeader = ({
  areaName,
  areaUpperName,
  onUpperNameClick,
  showAll,
  statusData,
  detailed,
  onTitleClick,
  typeMeter,
  date,
  setDate,
  tab,
  showBackButton = true
}) => {
  const name = areaName !== "" ? areaName : "Área sem nome";
  const [icon, setIcon] = useState();
  const [title, setTitle] = useState();

  const icons = {
    above: faArrowUp,
    below: faArrowDown,
    normal: faMinus,
    invalid: faMinus
  };

  const titles = {
    above: "Consumo subindo em comparação histórica",
    below: "Consumo caindo em comparação histórica",
    normal: "Consumo estável em comparação histórica",
    invalid: "Dados insuficientes"
  };

  useEffect(() => {
    if (showAll) {
      setIcon(null);
      setTitle(null);
    }
  }, [showAll]);

  useEffect(() => {
    setIcon(icons[statusData]);
    setTitle(titles[statusData]);
  }, [statusData]);

  return (
    <div className="area-header-container">
      <section style={{ display: "flex" }}>
        <div className="area-upper-name" onClick={onUpperNameClick}>
          {areaUpperName}
        </div>

        {(tab === "energy" || tab === "water") && (
          <div>
            <DateWidget
              type="energy"
              showAll={showAll}
              timeType="today"
              date={date}
              setDate={setDate}
            ></DateWidget>
          </div>
        )}
      </section>
      <section>
        <div className="area-name">
          {(!showAll || detailed) && showBackButton && (
            <FontAwesomeIcon
              className="back-icon"
              onClick={showAll ? onTitleClick : onUpperNameClick}
              icon={faChevronLeft}
              title="Voltar"
              size="1x"
            />
          )}
          {showAll ? "Todos os pontos de " + typeMeter : name}
          {!showAll && statusData && icon && (
            <div className={"status-indicator-header-wrapper " + statusData}>
              <FontAwesomeIcon
                className="status-indicator"
                icon={icon}
                title={title}
                size="xs"
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

AreaHeader.propTypes = {
  areaName: PropTypes.string,
  areaUpperName: PropTypes.string,
  onUpperNameClick: PropTypes.func
};

export default AreaHeader;

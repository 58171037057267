import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AlertRow from "./AlertRow";
import Label from "../Label/Label";
import AlertModal from "../AlertListWidget/AlertModal";
import AlertFilters from "./AlertFilters";
import { ListGroup, Row, Col, Button } from "react-bootstrap";
import ListFilter from "../ListFilter/ListFilter";
import "./AlertList.scss";
import { isLoading } from "../../selectors/data";
import { useSelector } from "react-redux";

const AlertList = ({
  alerts,
  onAlertClick,
  buildingId,
  buildings,
  isReadOnlyUser,
  triggers
}) => {
  let [modalShow, setModalShow] = useState(false);
  let [filteredAlerts, setFilteredAlerts] = useState(alerts);
  let loading = useSelector(isLoading);

  const [filters, setFilters] = useState({
    order: ["newest-first"],
    type: ["WARNING", "NOTICE", "ACTING"],
    status: ["read", "unread", "not-archived"],
    mitigated: ["mitigated", "not-mitigated"],
    trigger: triggers
  });

  useEffect(() => {
    setFilteredAlerts(alerts);
  }, [alerts]);

  const sortByDate = (x, y) => x.timestamp - y.timestamp;

  useEffect(() => {
    filterItems(alerts);
  }, [filters, alerts]);

  const filterItems = items => {
    let data = [...items];

    if (!filters.order.includes("newest-first")) data.sort(sortByDate);
    else data.sort(sortByDate).reverse();

    const filterTypes = ["WARNING", "NOTICE", "ACTING"];
    filterTypes.forEach(filterType => {
      if (!filters.type.includes(filterType))
        data = data.filter(alert => alert.type !== filterType);
    });

    if (!filters.status.includes("read") && filters.status.includes("unread"))
      data = data.filter(alert => !alert.read);
    if (!filters.status.includes("unread") && filters.status.includes("read"))
      data = data.filter(alert => alert.read);

    if (
      !filters.status.includes("archived") &&
      filters.status.includes("not-archived")
    )
      data = data.filter(alert => !alert.archived);
    if (
      !filters.status.includes("not-archived") &&
      filters.status.includes("archived")
    )
      data = data.filter(alert => alert.archived);
    if (
      !filters.mitigated.includes("mitigated") &&
      filters.mitigated.includes("not-mitigated")
    )
      data = data.filter(alert => !alert.mitigated);

    if (
      !filters.mitigated.includes("not-mitigated") &&
      filters.mitigated.includes("mitigated")
    )
      data = data.filter(alert => alert.mitigated);

    if (!filters.trigger.includes("all") && filters.trigger.length > 0)
      data = data.filter(alert => alert.trigger?.type === filters.trigger[0]);

    setFilteredAlerts(data);
  };

  const items = filteredAlerts.map(alert => {
    const building = buildings.find(b => b.buildingId === alert.buildingId);
    return (
      <AlertRow
        alert={alert}
        key={`${alert.id}-${alert.buildingId}`}
        onAlertClick={onAlertClick}
        building={building}
        alerts={alerts}
        filterItems={filterItems}
        isReadOnlyUser={isReadOnlyUser}
      />
    );
  });

  const renderAlerts = () => {
    if (filteredAlerts.length === 0)
      return (
        <Label
          subtitle={loading ? "Carregando alertas..." : "Não há alertas"}
        ></Label>
      );
    else
      return (
        <div className="list-alerts">
          <Row className="list-headers">
            <Col md={3}>
              <Row>Ponto de medição</Row>
            </Col>
            <Col md={6}>
              <Row>Alerta</Row>
            </Col>
            <Col md={3} className="last-header">
              Ações
            </Col>
          </Row>
          <ListGroup className="alerts">{items}</ListGroup>
        </div>
      );
  };

  const renderButton = buildingId => {
    if (buildingId) {
      return (
        <div style={{ textAlign: "right" }}>
          <Button
            id="add-alert"
            className="add-alert"
            variant="primary"
            onClick={() => setModalShow(true)}
          >
            Adicionar Alerta
          </Button>
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <div className="alert-list">
      <AlertModal
        buildingId={buildingId}
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
      <Row className="filters">
        <div className="filters-row">
          {AlertFilters(triggers).map((filter, index) => (
            <ListFilter
              filters={filters}
              filter={filter}
              onChangeFilter={setFilters}
              key={index}
            />
          ))}
        </div>
        <div>{!isReadOnlyUser && renderButton(buildingId)}</div>
      </Row>
      {renderAlerts()}
    </div>
  );
};

AlertList.propTypes = {
  alerts: PropTypes.array,
  onAlertClick: PropTypes.func,
  buildings: PropTypes.array,
  isReadOnlyUser: PropTypes.bool
};

export default AlertList;
